/*
 ** linx endponts
 */

export const getPopularTermAutocomplete = () => import('./getPopularTermAutocomplete');
export const getAutocompletes = () => import('./getAutocompletes');
export const getSearchLinx = () => import('./getSearchLinx');
export const getClickLinx = () => import('./getClickLinx');
export const getNavigatesLinx = () => import('./getNavigatesLinx');
export const getHotsitesLinx = () => import('./get-hotsites-linx');
export const getPagesLinx = () => import('./getPagesLinx');
export const getPagesRecommendations = () => import('./get-pages-recommendations');
export const fireLinxShowcaseEvents = () => import('./fire-linx-showcase-events');
